@import "_mixins.scss";
.operator-navigator {
    --ws-h: calc(1rem + .5vw);

    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    z-index: 6;
    flex: 0 0 var(--nav-bar-width);
    display: flex;
    flex-flow: column nowrap;
    width: var(--nav-bar-width);
    height: 100%;
    border-right: 1px solid var(--c-black-t1);
    background: var(--c-white);

    .nav-logo-wrap {
        padding: var(--ws-h);

        img {
            max-height: 3rem;
            display: block;
        }
    }

    .current-site {
        font-weight: normal;
        padding: 0 var(--ws-h);
        margin: 0 auto 0 0;

        &-name {font-weight: 700}
    }

    h1 {
        font-size: 3.125rem;
        color: var(--c-black);
        margin: .5rem 0;
    }

    .nav-menu {
        list-style: none;
        padding: 1.25em 0 0;
        margin: auto 0 0 0;
    }

    .nav-item {
        position: relative;

        &[inert] {
            background: var(--c-black-t2);
            color: var(--c-gray);

            .nav-link::after {border: none !important}
        }

        &:not(:last-child) .nav-link::after {
            content: '';
            position: absolute;
            inset: 100% var(--ws-h) auto;
            margin-top: -1px;
            border-bottom: 1px solid var(--c-gray-t4);
        }

        &:last-child .nav-link {padding-bottom: 1.25em}
    }

    .nav-link {
        display: block;
        padding: .8rem var(--ws-h);
        border-inline: .5rem solid #0000;
        filter: none !important;
        transition: background .1s, border-color .1s, text-indent .1s;

        &:hover {text-indent: .5rem}

        &[aria-current='page'] {border-left-color: var(--c-action-a)}
    }

    @media screen and (max-width: 64rem) {
        --ws-h: 1rem;

        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        border-right: none;
        box-shadow: var(--bs-realistic-faint);

        .nav-logo-wrap {
            display: flex;
            gap: 1rem;

            img {max-height: 2.5rem}
            svg {flex: 0 0 auto}
            h1 {font-size: 2rem}
        }

        .current-site {margin: 1rem 1rem 1rem auto}

        .nav-menu {display: none}
    }
}
