:root {
    // c = Color
    // Brand colors
    --c-action:           #52A333;
    --c-action-h:         #74B55B;
    --c-action-a:         #ABCF1F;
    --c-secondary-action: #CC601D;
    --c-background:       #FAFBFD;
    --c-title:            #124;
    --c-text:             #344;
    --c-link:             var(--c-action);
    --c-link-h:           var(--c-secondary-action);
    --c-success:          var(--c-action, #5A3);
    --c-warning:          #EB4;
    --c-error:            #D54;
    --c-selection:        #17E;
    --c-sponsored:        #FB4;
    // Useful colors
    --c-white:      #fff;
    --c-white-c1:   #eee;
    --c-black:      #000;
    --c-gray:       #777;
    --c-white-t4:   #fff4;
    --c-black-t1:   #0001;
    --c-black-t2:   #0002;
    --c-black-t3:   #0003;
    --c-gray-t4:    #7774;
    // TODO: Rename
    --heading-bg: #f7f7f7;
    // bs = Box-shadow
    --bs-focused:         0 .4rem 1.25rem var(--c-black-t3);
    --bs-realistic-faint: 0   .54197px   .54197px -1.250px rgba(0, 0, 0, .14),
                          0  2.05968px  2.05968px -2.500px rgba(0, 0, 0, .12711),
                          0  9.00000px  9.00000px -3.750px rgba(0, 0, 0, .05);
    --bs-realistic:       0   .70659px   .70659px  -.625px rgba(0, 0, 0, .15),
                          0  1.80656px  1.80656px -1.250px rgba(0, 0, 0, .14398),
                          0  3.62176px  3.62176px -1.875px rgba(0, 0, 0, .13793),
                          0  6.86560px  6.86560px -2.500px rgba(0, 0, 0, .12711),
                          0 13.64676px 13.64676px -3.125px rgba(0, 0, 0, .10451),
                          0 30.00000px 30.00000px -3.750px rgba(0, 0, 0, .05);
    // br = Border-radius
    --br-s:  4px;
    --br-m:  8px;
    --br-l: 17px;

    // ff = Font-family
    --ff-body:  'Twemoji Country Flags', 'Outfit',     sans-serif;
    --ff-title: 'Twemoji Country Flags', 'Sen',        'Work Sans', 'WorkSans', sans-serif;
    --ff-mono:  'Twemoji Country Flags', 'Code Saver', 'Consolas',  monospace;

    // Misc. stuff, mostly layout -related
    --nav-bar-width:            21.875rem;
    --viewport-width-min:       18rem;
    --common-page-padding-v:    4rem;
    --common-page-padding-v:    clamp(1rem, 13vh, 8rem);
    --common-page-padding-h:    7vw;
    --common-page-padding:      var(--common-page-padding-v) var(--common-page-padding-h);
}
