@import "_mixins.scss";
.language-switcher {
    @include label-m();
    font-weight: 400;
    border: 1px solid var(--c-black-t1);
    width: auto;
    transition: background .1s;

    &:hover {background: var(--c-white-c1)}
}
