@import "_mixins.scss";
.person-navigator {
    position: sticky;
    z-index: 1;
    top: 0;
    display: flex;
    gap: 1em 0;
    align-items: center;
    background: var(--c-white);
    border-bottom: 1px solid var(--c-black-t1);

    .logo-container {
        display: flex;
        margin-right: auto;
    }

    .nav-logo-wrap {
        img,
        svg {
            width: 100%;
            max-height: 2em;
            margin: 0;
        }
    }

    a {
        align-self: stretch;
        display: flex;
        align-items: center;
        padding: 1em;
    }

    .logout {padding-right: 1.5em}
}
