@import "_mixins.scss";
.core-wrapper {
    display: flex;
    flex-flow: row nowrap;
    height: 100vh;

    &.is-person {flex-flow: column nowrap}

    .core-content {
        overflow: auto;
        position: relative;
        flex: 1 1 auto;
        background: var(--c-background);
        scroll-behavior: smooth;

        > .language-switcher {
            position: absolute;
            z-index: 1;
            inset: 0 0 auto auto;
            margin: 1rem;
        }
    }

    @media screen and (max-width: 64rem) {flex-flow: column nowrap}
}
